import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../Layout/MainLayout";
import classes from "./edit-member.module.css";
import { env } from "../../constants/env";

const EditCompanyMember = () => {
  const { id } = useParams();
  const { companyId } = useParams();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [accessLevel, setAccessLevel] = useState([]);
  const [overviewOn, setOverviewOn] = useState(false);
  const [whitelabelOn, setWhitelabelOn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [themes, setThemes] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState(null);

  const navigate = useNavigate();

  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    getUser();
    getCompany();
  }, []);

  useEffect(() => {
    fetch(`${env.primeBridgeUrl}/api/company/themes`)
      .then((res) => res.json())
      .then((data) => setThemes(data))
      .catch((err) => console.error(err));
  }, []);

  const getUser = async () => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/auth/user/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setFirstName(result?.firstName);
      setLastName(result?.lastName);
      setEmail(result?.email);
      setAccessLevel(result?.accessLevel);
      setIsAdmin(result?.isAdmin);

      console.log("USER EDIT RESULTS", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const updateUserHandler = async () => {
    let dataContent = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      accessLevel: accessLevel,
      overview: overviewOn,
      whitelabel: whitelabelOn,
      theme: selectedTheme
    };
    const sendRequest = async () => {
      if(whitelabelOn && !selectedTheme) {
        alert("Please select a theme for whitelabel");
        return;
      }
      setIsSending(true);
      const response = await fetch(
        `${env.primeBridgeUrl}/api/auth/update-user/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent),
        }
      );

      if (!response.ok) {
        setIsSending(false);
        console.log("ERROR RESPONSE", response);
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsSending(false);

      navigate(`/company/${companyId}`);

      console.log("Updated User", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false);
      console.log(error);
    }
  };

  const getCompany = async () => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setOverviewOn(result.overview);
      setWhitelabelOn(result.whitelabel);
      setSelectedTheme(result.theme._id);

      console.log("Single Comapny data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const handleThemeChange = (event) => {
    const selectedTheme = themes.find(
      (theme) => theme._id === event.target.value
    );
    setSelectedTheme(event.target.value);
    console.log("Selected Theme", selectedTheme);
  };

  return (
    <MainLayout>
      <div className={classes.container}>
        <div className={classes.header}>
          <NavLink
            style={{ textDecoration: "none" }}
            to={`/company/${companyId}`}
          >
            <p className={classes.name}>Company Name</p>
          </NavLink>
          <h3 className={classes.title}>Edit Member</h3>
        </div>
        <div className={classes.form}>
          <p className={classes.title}>Login Information</p>

          <div className={classes.group}>
            <div className={classes.inputContainer}>
              <input
                placeholder="First Name"
                className={classes.input}
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className={classes.inputContainer}>
              <input
                placeholder="Last Name"
                className={classes.input}
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className={classes.inputContainer}>
            <input
              placeholder="Email"
              className={classes.input}
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {isAdmin ? (
            <div className={classes.access}>
              <p className={classes.title}>User Access</p>
              <label class={`${classes.check_cont}`}>
                <input
                  type="checkbox"
                  checked={overviewOn}
                  onChange={(e) => setOverviewOn(!overviewOn)}
                />
                <span className={classes.checkmark}></span>
                <p>Overview section</p>
              </label>{" "}
              <label class={`${classes.check_cont}`}>
                <input
                  type="checkbox"
                  checked={whitelabelOn}
                  onChange={(e) => setWhitelabelOn(!whitelabelOn)}
                />
                <span className={classes.checkmark}></span>
                <p>Whitelabel</p>
              </label>
              {whitelabelOn && <div>
                <select
                  onChange={handleThemeChange}
                  style={{
                    padding: "8px 10px",
                    fontSize: "16px",
                    borderRadius: "100px",
                    border: "1px solid #ccc",
                  }}
                >
                  <option value="">Select a theme</option>
                  {themes.map((theme) => (
                    <option key={theme._id} value={theme._id} selected={selectedTheme === theme._id}>
                      {theme.name}
                    </option>
                  ))}
                </select>
              </div>}
            </div>
          ) : (
            <div className={classes.access}>
              <p className={classes.title}>User Access</p>
              <div>
                <label class={classes.check_cont}>
                  <input
                    type="checkbox"
                    checked={accessLevel.includes("asset-dashboard")}
                    onChange={(e) => {
                      if (!accessLevel.includes("asset-dashboard")) {
                        setAccessLevel([...accessLevel, "asset-dashboard"]);
                      } else {
                        setAccessLevel((current) =>
                          current.filter(
                            (access) => access !== "asset-dashboard"
                          )
                        );
                      }
                    }}
                  />
                  <span className={classes.checkmark}></span>
                  <p>Asset Dashboard</p>
                </label>
                <label class={classes.check_cont}>
                  <input
                    type="checkbox"
                    checked={accessLevel.includes("transactions")}
                    onChange={(e) => {
                      if (!accessLevel.includes("transactions")) {
                        setAccessLevel([...accessLevel, "transactions"]);
                      } else {
                        setAccessLevel((current) =>
                          current.filter((access) => access !== "transactions")
                        );
                      }
                    }}
                  />
                  <span className={classes.checkmark}></span>
                  <p>Transactions</p>
                </label>
                <label class={classes.check_cont}>
                  <input
                    type="checkbox"
                    checked={accessLevel.includes("team")}
                    onChange={(e) => {
                      if (!accessLevel.includes("team")) {
                        setAccessLevel([...accessLevel, "team"]);
                      } else {
                        setAccessLevel((current) =>
                          current.filter((access) => access !== "team")
                        );
                      }
                    }}
                  />
                  <span className={classes.checkmark}></span>
                  <p>Team</p>
                </label>
                <label class={classes.check_cont}>
                  <input
                    type="checkbox"
                    checked={accessLevel.includes("help-center")}
                    onChange={(e) => {
                      if (!accessLevel.includes("help-center")) {
                        setAccessLevel([...accessLevel, "help-center"]);
                      } else {
                        setAccessLevel((current) =>
                          current.filter((access) => access !== "help-center")
                        );
                      }
                    }}
                  />
                  <span className={classes.checkmark}></span>
                  <p>Help Center</p>
                </label>
                {overviewOn && (
                  <label class={classes.check_cont}>
                    <input
                      type="checkbox"
                      checked={accessLevel.includes("overview")}
                      onChange={(e) => {
                        if (!accessLevel.includes("overview")) {
                          setAccessLevel([...accessLevel, "overview"]);
                        } else {
                          setAccessLevel((current) =>
                            current.filter((access) => access !== "overview")
                          );
                        }
                      }}
                    />
                    <span className={classes.checkmark}></span>
                    <p>Overview section</p>
                  </label>
                )}
              </div>
            </div>
          )}

          <div className={classes.btnContainer}>
            {!isSending && (
              <>
                <Link
                  style={{ textDecoration: "none", display: "block" }}
                  to={`/company/${companyId}`}
                >
                  <button className={classes.cancel}>Cancel</button>
                </Link>

                <Link style={{ textDecoration: "none", display: "block" }}>
                  <button
                    className={classes.confirm}
                    onClick={updateUserHandler}
                  >
                    Save
                  </button>
                </Link>
              </>
            )}
            {isSending && <div className="spinner-border" role="status"></div>}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default EditCompanyMember;
